import React from 'react';
import { useEffect } from 'react';
import Header from '../../common/Header/Header';
import HeroSection from '../../common/HeroSection/HeroSection';
import InfoCards from '../../main/InfoCard/InfoCards';
import About from '../../main/About/About';
import Services from '../../main/Services/Services';
import TestimonialSlider from '../../main/Testimony/Testimony';
import AppointmentForm from '../../main/AppointmentForm/AppointmentForm';
import Footer from '../../common/Footer/Footer';
import ExclusiveOffers from '../../common/ExclusiveOffers/ExclusiveOffers';
import "./HomePage.css";

const HomePage = () => {
  useEffect(() => {
    // Add Google Fonts
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&family=Lato:wght@300;400;700&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <div className="App">
      <Header />
      <main>
        <HeroSection />
        <ExclusiveOffers />
        <section className="gradient-bg">
          <InfoCards />
        </section>
        <About />
        <section className="gradient-bg">
          <TestimonialSlider />
        </section>
        <Services />
        <AppointmentForm />
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;
