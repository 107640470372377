import React, { useState } from 'react';
import './Header.css';
import { FaBars, FaTimes, FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { scrollToSection, scrollToTop } from '../../../utils/scrollUtils';
import AppointmentForm from '../AppointmentForm/AppointmentForm';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showAppointmentForm, setShowAppointmentForm] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavClick = (sectionClass) => {
    if (sectionClass === 'home') {
      scrollToTop();
    } else {
      scrollToSection(`.${sectionClass}`);
    }
    setIsMenuOpen(false);
  };

  const toggleAppointmentForm = () => {
    setShowAppointmentForm(!showAppointmentForm);
  };

  return (
    <header className="header">
      <div className="top-bar">
        <div className="contact-info">
          <div>
            <a href="tel:+917204461364" className="contact-item"><FaPhone /> +91 7204461364</a>
            <a href="tel:+919175181049" className="contact-item"> / +91 9175181049</a>
          </div>
          <a href="mailto:drholisticharmony@gmail.com" className="contact-item">
            <FaEnvelope /> drholisticharmony@gmail.com
          </a>
          <span className="contact-item">
            <FaMapMarkerAlt /> Kothanur, Bangalore
          </span>
        </div>
      </div>

      <div className="header-content">
        <a onClick={() => handleNavClick('home')} className="logo" style={{ cursor: 'pointer' }}>Dr. Holistic Harmony</a>

        <button className="menu-btn" onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </button>

        <nav className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
          <div className="mobile-contact-info">
              <a href="tel:+917204461364" className="contact-item"><FaPhone /> +91 7204461364</a>
              <a href="tel:+919175181049" className="contact-item"><FaPhone /> +91 9175181049</a>
            <a href="drholisticharmony@gmail.com" className="contact-item">
              <FaEnvelope /> drholisticharmony@gmail.com
            </a>
          </div>
          <a onClick={() => handleNavClick('home')} style={{ cursor: 'pointer' }}>Home</a>
          <a onClick={() => handleNavClick('about')} style={{ cursor: 'pointer' }}>About</a>
          <a onClick={() => handleNavClick('services-section')} style={{ cursor: 'pointer' }}>Services</a>
          <a onClick={() => handleNavClick('testimony-section')} style={{ cursor: 'pointer' }}>Testimonials</a>
          <a onClick={() => handleNavClick('info-cards')} style={{ cursor: 'pointer' }}>Location</a>
          <button className="book-appointment-btn" onClick={() => handleNavClick('appointment')}>
            Book Appointment
          </button>
        </nav>
      </div>

      {showAppointmentForm && (
        <>
          <div className="appointment-overlay" onClick={toggleAppointmentForm} />
          <div className="appointment-modal">
            <AppointmentForm onClose={toggleAppointmentForm} />
          </div>
        </>
      )}
    </header>
  );
};

export default Header;
