import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-section">
                    <h3>Contact Info</h3>
                    <a href="https://www.google.com/maps/place/Dr+Holistic+Harmony+Acupuncture,Physiotherapy-Acupunture+near+me,Acupuncturist+Near+me,Physiotherapy+near+me,Best+Acupunture./@13.060975,77.6437201,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae172652cec1e5:0x92427efac6ab5f48!8m2!3d13.060975!4d77.648591!16s%2Fg%2F11txy0d2sv?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D">4th floor, Patel Hanumakka complex, Hennur Bagalur Main Rd, above HDFC Bank, Kothanur, Bengaluru, Karnataka 560077</a>
                    <p>Email: drholisticharmony@gmail.com</p>
                    <a href="tel:+917204461364">Contact Us: +91 7204461364</a>
                    <a href="tel:+919175181049"> / +91 9175181049</a>
                </div>
                <p className="footer-bottom">&copy; 2024 drholisticharmony. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
