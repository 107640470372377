import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Row, Col, message } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import apiClient from "../../../../common/LoginV2/axiosClient";
import hospitalLogo from "../../../../images/hospitallogo.jpg";
import jsPDF from "jspdf";
import "jspdf-autotable";

const GenerateBillModal = ({ visible, onClose, patientInfo }) => {
  const [form] = Form.useForm();
  const [remainingBalance, setRemainingBalance] = useState(patientInfo?.previousBalance || 0);
  const [billPaidAmount, setBillPaidAmount] = useState(0);
  const [receiptNumber, setReceiptNumber] = useState("");

  const totalWords = (num) => {
    const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    const teens = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    const thousands = ["", "Thousand", "Million", "Billion"];

    if (num === 0) return "Zero";

    const convertToWords = (n) => {
        let result = "";

        if (n >= 100) {
            result += ones[Math.floor(n / 100)] + " Hundred ";
            n %= 100;
        }

        if (n >= 10 && n < 20) {
            result += teens[n - 10] + " ";
        } else if (n >= 20) {
            result += tens[Math.floor(n / 10)] + " ";
            n %= 10;
        }

        if (n > 0 && n < 10) {
            result += ones[n] + " ";
        }

        return result.trim();
    };

    let word = "";
    let i = 0;

    while (num > 0) {
        if (num % 1000 !== 0) {
            word = convertToWords(num % 1000) + " " + thousands[i] + " " + word;
        }
        num = Math.floor(num / 1000);
        i++;
    }

    return word.trim() + " Only";
};

  useEffect(() => {
    if (visible) {
      const generatedReceiptNumber = Math.floor(
        100000000000 + Math.random() * 900000000000
      ).toString();
      setReceiptNumber(generatedReceiptNumber);

      form.setFieldsValue({
        patientName: patientInfo?.patientName || "",
        phoneNumber: patientInfo?.phoneNumber || "",
      });
    }
  }, [visible, patientInfo, form]);

  const handleAmountChange = () => {
    const values = form.getFieldsValue();
    const totalBillingAmount = values.packages.reduce(
      (sum, pkg) => sum + Number(pkg.billingAmount || 0),
      0
    );
    const remaining =
      totalBillingAmount + patientInfo?.previousBalance - (values.paidAmount || 0);
    setBillPaidAmount(values.paidAmount);
    setRemainingBalance(remaining >= 0 ? remaining : 0);
  };

  const handleGenerateBill = async () => {
    const values = form.getFieldsValue();
    const totalBillingAmount = values?.packages?.reduce(
      (sum, pkg) => sum + Number(pkg?.billingAmount || 0),
      0
    );
    const appointmentId = Object.keys(patientInfo?.records || {})[0];;

    if (!appointmentId) {
      message.error("Appointment ID is missing.");
      return;
    }

    const billData = {
      patientId: patientInfo.patientId,
      appointmentId,
      packages: values.packages,
      paidAmount: billPaidAmount,
      previousRemainingAmount: patientInfo.previousBalance,
      totalRemainingAmount: remainingBalance,
      totalAmount: totalBillingAmount,
      receiptNumber,
    };

    try {
      const response = await apiClient.post("/billing", billData);

      if (response.status === 200) {
        message.success("Bill generated successfully");
        form.resetFields();
        onClose();
      }
    } catch (error) {
      message.error("Failed to generate bill");
      console.error(error);
    }
  };

  const handleDownloadBill = () => {
    const values = form.getFieldsValue();
    const doc = new jsPDF();

    doc.addImage(hospitalLogo, "PNG", 0, 5, 70, 40);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(9);

    const lineHeight = 5;
    const numberHeight = 6;

    doc.text("4th floor, Patel Hanumakka complex,", 140, 20);
    doc.text("Hennur Bagalur Main Rd, above HDFC ", 140, 20 + lineHeight);
    doc.text("Bank, Kothanur, Bengaluru - 560077", 140, 20 + 2 * lineHeight);
    doc.text("Phone Number: +91 91751 81049", 140, 20 + 3 * numberHeight);

    doc.setFontSize(18);
    doc.text("Receipt", 95, 40, { align: "center" });
    doc.setLineWidth(0.5);
    doc.line(10, 45, 200, 45);

    doc.setFontSize(12);
    const startY = 55;
    const col1X = 10;
    const col2X = 125;

    const totalBillingAmount = values?.packages?.reduce(
      (sum, pkg) => sum + Number(pkg?.billingAmount || 0),
      0
    );

    doc.text(`Name: ${values.patientName}`, col1X, startY);
    doc.text(`Mobile Number: ${values.phoneNumber}`, col2X, startY);
    doc.text(`Cashier Name: ${values.cashierName || ""}`, col1X, startY + 10);
    doc.text(`Cashier ID: ${values.cashierId || ""}`, col2X, startY + 10);
    doc.text(`Total Amount: ${totalBillingAmount}`, col1X, startY + 20);
    doc.text(`Paid Amount: ${values.paidAmount}`, col2X, startY + 20);
    doc.text(`Due Amount: ${remainingBalance}`, col1X, startY + 30);

    const packagesData = values.packages.map((pkg, index) => [
      index + 1,
      pkg.packageName,
      pkg.billingAmount,
    ]);

    doc.autoTable({
      startY: startY + 40,
      head: [["S.No", "Package Name", "Billing Amount"]],
      body: packagesData,
      theme: "grid",
      headStyles: { fillColor: [0,0,0] },
      bodyStyles: { fontSize: 10, halign: "center" },
    });

    const grandTotalY = startY + 70;

    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text(`Grand Total: ${totalBillingAmount}`, col2X, grandTotalY);

    const grandTotalWords = totalWords(totalBillingAmount);
    const wordsY = grandTotalY;
    doc.text("In words: ", col1X, wordsY);
    doc.text(grandTotalWords, col1X + doc.getTextWidth("In words: "), wordsY);

    const termsY = doc.autoTable.previous.finalY + 30;
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text("Terms and Conditions", 105, termsY, { align: "center" });
    doc.setLineWidth(0.5);
    doc.line(10, termsY + 5, 200, termsY + 5);

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");

    let currentY = termsY + 15;

    const terms = [
      "\u2022 The facilities of joining the card include any number of consultations with a physician",
      "\u2022 The card facilities are given only to the one on whose name the card is made",
      "• The fee is non-transferable, non-refundable, and non-extendable.",
      "• Patients are strictly advised to use medicines as per attending physician's recommendation.",
      "• We expect and would appreciate patients to visit the clinic as per the due date of their consultations.",
      "• Patients are asked to cooperate with the treatment, as recovery may be slow at times.",
      "• The duration of treatment and results may vary from patient to patient.",
      "• The doctor and clinic do not guarantee the results or duration of the treatment.",
      "• During critical emergencies, patients/attendants are advised to inform the attending physician.",
      "• This corporate clinic promises to provide the best service and treatment to all patients.",
    ];

    terms.forEach((term) => {
      doc.text(term, 10, currentY);
      currentY += 10; // Add space between terms
    });

    // Signature Section
    const signatureY = currentY;
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(
      "Patient / Attendants Signature: ____________________",
      10,
      signatureY
    );
    doc.text(`Mobile Number: ${values.phoneNumber}`, 10, signatureY + 7);
    doc.text("Authorized Signatory: _________________", 120, signatureY);
    doc.text(`Cashier Name: ${values.cashierName || ""}`, 120, signatureY + 7);
    doc.text(`Cashier ID: ${values.cashierId || ""}`, 120, signatureY + 14);

    doc.line(10, signatureY + 20, 200, signatureY + 20); // Line below signatures

    // Footer
    const footerY = signatureY + 30;
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text("Bangalore, Karnataka, India", 55, footerY, { align: "center" });
    doc.text(
      "Email: drholisticharmony.com, Mobile: +91 91751 81049",
      135,
      footerY,
      { align: "center" }
    );

    // Save the PDF
    doc.save(`bill_${values.patientName}_${receiptNumber}.pdf`);
  };

  return (
    <Modal
      title="Generate Bill"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={1000}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ packages: [{ packageName: "", billingAmount: 0 }] }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Patient Name" name="patientName">
              <Input placeholder="Enter Patient Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Phone Number" name="phoneNumber">
              <Input placeholder="Enter Phone Number" />
            </Form.Item>
          </Col>
        </Row>

        <Form.List name="packages">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Row gutter={16} key={field.key}>
                  <Col span={10}>
                    <Form.Item
                      {...field}
                      label="Package Name"
                      name={[field.name, "packageName"]}
                      fieldKey={[field.fieldKey, "packageName"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter package name",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Package Name" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      {...field}
                      label="Billing Amount"
                      name={[field.name, "billingAmount"]}
                      fieldKey={[field.fieldKey, "billingAmount"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter billing amount",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder="Enter Amount"
                        onChange={handleAmountChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Button
                      type="danger"
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(field.name)}
                      style={{ marginTop: "28px" }}
                    />
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add Package
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Paid Amount" name="paidAmount">
              <Input
                type="number"
                placeholder="Enter Paid Amount"
                onChange={handleAmountChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Remaining Balance">
              <Input value={remainingBalance} readOnly />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Cashier Name" name="cashierName">
              <Input placeholder="Enter Cashier Name (Optional)" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Cashier ID" name="cashierId">
              <Input placeholder="Enter Cashier ID (Optional)" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Receipt Number">
              <Input value={receiptNumber} readOnly />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            type="primary"
            onClick={() => {
              handleDownloadBill(); // Ensure this function is called
              handleGenerateBill(); // Ensure this function is called
            }}
          >
            Generate Bill and Download PDF
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default GenerateBillModal;
