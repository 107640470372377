export const scrollToSection = (sectionClass) => {
    const section = document.querySelector(sectionClass);
    if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
    }
};

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};
