import React, { useState } from 'react';
import './ExclusiveOffers.css';
import { FaLeaf, FaYinYang, FaSpa, FaClinicMedical, FaBandAid, FaHandHoldingMedical, FaTimes } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { scrollToSection } from '../../../utils/scrollUtils';

const offers = [
    {
        title: "Hair Growth Treatment",
        category: "Trichology",
        icon: <FaSpa />,
        treatments: [
            { name: "PRP", price: "₹2,500" },
            { name: "GFC", price: "₹3,500" }
        ],
        description: "Advanced hair growth treatments using cutting-edge technology",
        color: "#9C27B0"
    },
    {
        title: "Yoga Classes",
        category: "Wellness",
        icon: <FaYinYang />,
        treatments: [
            { name: "Personal Sessions", price: "₹1,299" }
        ],
        description: "Personalized yoga sessions for mind-body wellness",
        color: "#4CAF50"
    },
    {
        title: "Diet Plans",
        category: "Nutrition",
        icon: <FaLeaf />,
        treatments: [
            { name: "Personalized Diet", price: "₹1,499" }
        ],
        description: "Customized nutrition plans for your health goals",
        color: "#FF9800"
    },
    {
        title: "Acupuncture & Physiotherapy",
        category: "Treatment",
        icon: <FaBandAid />,
        treatments: [
            { name: "Session", price: "₹650" }
        ],
        description: "Natural pain relief and healing therapies",
        color: "#E91E63"
    },
    {
        title: "Homeopathy",
        category: "Medicine",
        icon: <FaClinicMedical />,
        treatments: [
            { name: "Package", price: "₹1,199" }
        ],
        description: "Holistic homeopathic treatments for various conditions",
        color: "#2196F3"
    },
    {
        title: "Reversal Program",
        category: "Special",
        icon: <FaHandHoldingMedical />,
        treatments: [
            { name: "3-6 months Program", price: "₹4,999/month" }
        ],
        description: "Comprehensive program for Diabetes/Obesity/Thyroid/Pcod/PCOS/Infertility",
        color: "#673AB7"
    }
];

const ExclusiveOffers = () => {
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (offer) => {
        setSelectedOffer(offer);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setTimeout(() => setSelectedOffer(null), 300);
    };

    const handleBookNow = () => {
        scrollToSection('.appointment');
        if (selectedOffer) {
            setSelectedOffer(null);
        }
    };

    return (
        <section className="exclusive-offers">
            <div className="offers-container">
                <motion.div 
                    className="section-header"
                    initial={{ opacity: 0, y: -50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                >
                    <h2>Exclusive Offers</h2>
                    <p className="section-subtitle">Limited Time Special Packages</p>
                </motion.div>

                <div className="offers-list">
                    {offers.map((offer, index) => (
                        <motion.div 
                            key={index}
                            className="offer-item"
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            onClick={() => openModal(offer)}
                        >
                            <div className="offer-icon" style={{ backgroundColor: offer.color }}>
                                {offer.icon}
                            </div>
                            <div className="offer-details">
                                <div className="offer-header">
                                    <h3>{offer.title}</h3>
                                    <span className="offer-category" style={{ color: offer.color }}>
                                        {offer.category}
                                    </span>
                                </div>
                                <p className="offer-description">{offer.description}</p>
                                <div className="offer-pricing">
                                    {offer.treatments.map((treatment, idx) => (
                                        <div key={idx} className="price-item">
                                            <span className="treatment-name">{treatment.name}</span>
                                            <span className="treatment-price" style={{ color: offer.color }}>
                                                {treatment.price}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <motion.button 
                                className="view-details"
                                style={{ 
                                    borderColor: offer.color,
                                    color: offer.color
                                }}
                                whileHover={{ 
                                    backgroundColor: offer.color,
                                    color: '#fff'
                                }}
                            >
                                View Details
                            </motion.button>
                        </motion.div>
                    ))}
                </div>

                <div className="offers-banner">
                    <marquee behavior="scroll" direction="left">
                        <span>🌟 Limited-Time Inaugural Offers! Grab Them Before They're Gone!</span>
                        <span> ✨ Special Introductory Prices! Don't Miss Out, Act Now!</span>
                        <span> ✨ We combine Acupuncture/Diet/Yoga/Homeopathy medicine</span>
                    </marquee>
                </div>
            </div>

            <AnimatePresence>
                {isModalOpen && selectedOffer && (
                    <>
                        <motion.div 
                            className="modal-overlay"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            onClick={closeModal}
                        />
                        <motion.div 
                            className="offer-modal"
                            initial={{ opacity: 0, scale: 0.8, y: 50 }}
                            animate={{ opacity: 1, scale: 1, y: 0 }}
                            exit={{ opacity: 0, scale: 0.8, y: 50 }}
                        >
                            <button className="close-modal" onClick={closeModal}>
                                <FaTimes />
                            </button>
                            <div className="modal-icon" style={{'--card-color': selectedOffer.color}}>
                                {selectedOffer.icon}
                            </div>
                            <div className="modal-category">{selectedOffer.category}</div>
                            <h3>{selectedOffer.title}</h3>
                            <p className="modal-description">{selectedOffer.description}</p>
                            <div className="modal-treatments">
                                {selectedOffer.treatments.map((treatment, idx) => (
                                    <div key={idx} className="modal-treatment">
                                        <span className="name">{treatment.name}</span>
                                        <span className="price">{treatment.price}</span>
                                    </div>
                                ))}
                            </div>
                            <div className="modal-features">
                                <h4>Package Includes:</h4>
                                <ul>
                                    <li>Initial Consultation</li>
                                    <li>Personalized Treatment Plan</li>
                                    <li>Regular Progress Monitoring</li>
                                    <li>Expert Guidance</li>
                                </ul>
                            </div>
                            <motion.button
                                className="book-now-btn"
                                onClick={handleBookNow}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                Book Now
                            </motion.button>
                        </motion.div>
                    </>
                )}
            </AnimatePresence>
        </section>
    );
};

export default ExclusiveOffers;
