import React from 'react';
import './InfoCards.css';
import { FaClock, FaMapMarkerAlt } from 'react-icons/fa';

const InfoCards = () => {
    return (
        <section className="info-cards" id="consultation-hours">
            <div className="section-header">
                <h2>Visit Information</h2>
                <p className="section-subtitle">Everything you need to know about visiting us</p>
            </div>
            <div className="info-cards-container">
                <div className="card doctor-timetable-card animate-fade-in">
                    <div className="card-icon">
                        <FaClock />
                    </div>
                    <h3>Consultation Hours</h3>
                    <p className="card-description">Our doctors are available throughout the week. Find the most convenient time for your visit.</p>
                    <div className="timetable-wrapper">
                        <table className="timetable">
                            <thead>
                                <tr>
                                    <th>Day</th>
                                    <th>Working Hours</th>
                                </tr>
                            </thead>
                            <tbody>
                                {[
                                    'Monday', 'Tuesday', 'Wednesday', 'Thursday',
                                    'Friday', 'Saturday', 'Sunday'
                                ].map((day) => (
                                    <tr key={day}>
                                        <td>{day}</td>
                                        <td>9:00 AM - 8:00 PM</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card location-card animate-fade-in">
                    <div className="card-icon">
                        <FaMapMarkerAlt />
                    </div>
                    <h3>Find Us Here</h3>
                    <div className="location-content">
                        <p className="card-description">Visit us at our conveniently located facility. We are situated at the heart of Bengaluru, easily accessible and ready to serve you.</p>
                        <div className="address-block">
                            <h4>Address</h4>
                            <p>4th floor, Patel Hanumakka complex, Hennur Bagalur Main Rd, above HDFC Bank, Kothanur, Bengaluru, Karnataka 560077</p>
                        </div>
                        <div className="button-container">
                            <a 
                                href="https://www.google.com/maps/place/Dr+Holistic+Harmony+Acupuncture,Physiotherapy-Acupunture+near+me,Acupuncturist+Near+me,Physiotherapy+near+me,Best+Acupunture./@13.060975,77.6437201,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae172652cec1e5:0x92427efac6ab5f48!8m2!3d13.060975!4d77.648591!16s%2Fg%2F11txy0d2sv?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D" 
                                className="fancy-link"
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                Get Directions
                                <span className="arrow">→</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InfoCards;
