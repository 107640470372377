import { Layout } from 'antd';
import HomePage from "./components/Pages/HomePage/HomePage";
import AdminDashboard from "./components/Pages/AdminDashboard/AdminDashboard";
import AppointmentDashboard from "./components/main/AppointmentDashboard/AppointmentDashboard";
import ShowAppointments from "./components/Pages/AdminDashboard/ShowAppointments/ShowAppointments";
import AppointmentRecordManagement from "./components/Pages/AdminDashboard/AppointmentRecordManagement/AppointmentRecordManagement";
import LoginV2 from "./components/common/LoginV2/LoginV2";
import DepartmentPage from "./components/Pages/AdminDashboard/DepartmentPage/DepartmentPage";
import PatientRecordsAndBilling from "./components/Pages/AdminDashboard/PatientRecordsAndBilling/PatientRecordsAndBilling";
import UserManagementPage from "./components/Pages/AdminDashboard/UserManagementPage/UserManagementPage";
import AppointmentManagementPage from "./components/Pages/AdminDashboard/AppointmentManagementPage/AppointmentManagementPage";
import BillGenerator from "./components/Pages/BillingPage/BillingPage";
import BreadcrumbNav from "./components/common/BreadCrumbNav/BreadCrumbNav";
import { useState, useEffect } from "react";
import Dashboard from "./components/common/Dashboard/Dashboard";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import Sidebar from "./components/common/Sidebar/Sidebar";
import PasswordUpdate from "./components/common/PasswordUpdate/PasswordUpdate";
import "bootstrap/dist/css/bootstrap.min.css";
import { useMediaQuery } from "react-responsive";
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme';
import { GlobalStyles } from './styles/GlobalStyles';

const { Content } = Layout;

const ProtectedRoute = ({ children }) => {
  const authToken = sessionStorage.getItem("authToken");

  if (!authToken) {
    return <Navigate to="/login" />;
  }

  return children;
};

const AppContent = () => {
  const location = useLocation();
  const hideSidebar = ["/login", "/"].includes(location.pathname);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout style={{ flexDirection: isMobile ? "column" : "row" }}>
        {!hideSidebar && !isMobile && <Sidebar isMobile={isMobile} />}
        {!hideSidebar && isMobile && <BreadcrumbNav />}
        <Layout>
          <Content style={{ minHeight: 280 }}>
            <Routes>
              {/* Public route */}
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginV2 />} />
              <Route
                path="/AppointmentDashboard"
                element={
                  <ProtectedRoute>
                    <AppointmentDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/showAppointments"
                element={
                  <ProtectedRoute>
                    <ShowAppointments />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute>
                    <AdminDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/DepartmentPage"
                element={
                  <ProtectedRoute>
                    <DepartmentPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/UserManagementPage"
                element={
                  <ProtectedRoute>
                    <UserManagementPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/AppointmentManagementPage"
                element={
                  <ProtectedRoute>
                    <AppointmentManagementPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/AppointmentRecordManagement"
                element={
                  <ProtectedRoute>
                    <AppointmentRecordManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/PatientRecordsAndBilling"
                element={
                  <ProtectedRoute>
                    <PatientRecordsAndBilling />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/PasswordUpdate"
                element={
                  <ProtectedRoute>
                    <PasswordUpdate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/billing-darkMode"
                element={<BillGenerator />}
              />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
}

export default App;
