import React, { useState, useEffect } from 'react';
import './Testimony.css';
import { motion, AnimatePresence } from 'framer-motion';
import { FaQuoteLeft, FaQuoteRight, FaStar, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const testimonials = [
    {
        id: 1,
        name: "Sarah Johnson",
        role: "Yoga Enthusiast",
        image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80",
        text: "The holistic approach to wellness here is truly transformative. The yoga sessions have not only improved my flexibility but also brought peace to my mind.",
        rating: 5
    },
    {
        id: 2,
        name: "Michael Chen",
        role: "Wellness Seeker",
        image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80",
        text: "After struggling with chronic stress, the combination of acupuncture and personalized diet plans has made a remarkable difference in my life.",
        rating: 5
    },
    {
        id: 3,
        name: "Emily Rodriguez",
        role: "Fitness Enthusiast",
        image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80",
        text: "The expert guidance and personalized attention I received here exceeded all my expectations. My journey to wellness has been incredible.",
        rating: 5
    },
    {
        id: 4,
        name: "David Thompson",
        role: "Health Coach",
        image: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80",
        text: "The holistic treatments have helped me achieve balance in both body and mind. The staff's dedication to wellness is truly inspiring.",
        rating: 5
    }
];

const Testimony = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState(0);
    const [isAutoPlaying, setIsAutoPlaying] = useState(true);

    useEffect(() => {
        let interval;
        if (isAutoPlaying) {
            interval = setInterval(() => {
                nextSlide();
            }, 5000);
        }
        return () => clearInterval(interval);
    }, [currentIndex, isAutoPlaying]);

    const nextSlide = () => {
        setDirection(1);
        setCurrentIndex((prevIndex) => 
            prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
        );
    };

    const prevSlide = () => {
        setDirection(-1);
        setCurrentIndex((prevIndex) => 
            prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
        );
    };

    const slideVariants = {
        enter: (direction) => ({
            x: direction > 0 ? 1000 : -1000,
            opacity: 0
        }),
        center: {
            zIndex: 1,
            x: 0,
            opacity: 1
        },
        exit: (direction) => ({
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
        })
    };

    const swipeConfidenceThreshold = 10000;
    const swipePower = (offset, velocity) => {
        return Math.abs(offset) * velocity;
    };

    return (
        <section className="testimony-section">
            <div className="testimony-container">
                <motion.div 
                    className="section-header"
                    initial={{ opacity: 0, y: -50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                >
                    <h2>What Our Clients Say</h2>
                    <p>Real stories from real people on their journey to wellness</p>
                </motion.div>

                <div className="testimonial-slider"
                    onMouseEnter={() => setIsAutoPlaying(false)}
                    onMouseLeave={() => setIsAutoPlaying(true)}
                >
                    <AnimatePresence initial={false} custom={direction}>
                        <motion.div
                            key={currentIndex}
                            className="testimonial-card"
                            custom={direction}
                            variants={slideVariants}
                            initial="enter"
                            animate="center"
                            exit="exit"
                            transition={{
                                x: { type: "spring", stiffness: 300, damping: 30 },
                                opacity: { duration: 0.2 }
                            }}
                            drag="x"
                            dragConstraints={{ left: 0, right: 0 }}
                            dragElastic={1}
                            onDragEnd={(e, { offset, velocity }) => {
                                const swipe = swipePower(offset.x, velocity.x);
                                if (swipe < -swipeConfidenceThreshold) {
                                    nextSlide();
                                } else if (swipe > swipeConfidenceThreshold) {
                                    prevSlide();
                                }
                            }}
                        >
                            <div className="quote-icon left">
                                <FaQuoteLeft />
                            </div>
                            <div className="quote-icon right">
                                <FaQuoteRight />
                            </div>
                            <div className="testimonial-content">
                                <div className="testimonial-image">
                                    <img src={testimonials[currentIndex].image} alt={testimonials[currentIndex].name} />
                                </div>
                                <p className="testimonial-text">{testimonials[currentIndex].text}</p>
                                <div className="testimonial-rating">
                                    {[...Array(testimonials[currentIndex].rating)].map((_, i) => (
                                        <FaStar key={i} />
                                    ))}
                                </div>
                                <h3 className="testimonial-name">{testimonials[currentIndex].name}</h3>
                                <p className="testimonial-role">{testimonials[currentIndex].role}</p>
                            </div>
                        </motion.div>
                    </AnimatePresence>

                    <button className="nav-button prev" onClick={prevSlide}>
                        <FaChevronLeft />
                    </button>
                    <button className="nav-button next" onClick={nextSlide}>
                        <FaChevronRight />
                    </button>

                    <div className="testimonial-dots">
                        {testimonials.map((_, index) => (
                            <button
                                key={index}
                                className={`dot ${index === currentIndex ? 'active' : ''}`}
                                onClick={() => {
                                    setDirection(index > currentIndex ? 1 : -1);
                                    setCurrentIndex(index);
                                }}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimony;
