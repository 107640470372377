import React from "react";
import "./About.css";
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

const About = () => {
  const images = [
    require("../../images/abhisha.jpg"),
    require("../../images/chirag.jpg"),
  ];

  return (
    <section className="about" id="about-us">
      <div className="about-container">
        <div className="section-header">
          <h2>Meet Our Experts</h2>
          <p className="section-subtitle">
            Dedicated professionals committed to your holistic well-being
          </p>
        </div>
        
        <div className="about-content">
          <div className="founder-card">
            <div className="founder-image-wrapper">
              <img src={images[0]} alt="Dr. Abhilasha Maske" />
              <div className="image-overlay"></div>
            </div>
            <div className="founder-details">
              <h3>Dr. Abhilasha Maske</h3>
              <span className="designation">Naturopathy & Wellness Expert</span>
              <div className="quote-container">
                <FaQuoteLeft className="quote-icon left" />
                <p className="founder-bio">
                  Dr. Abhilasha Maske is a graduate of the Rajiv Gandhi University
                  of Health Sciences, Bangalore where she completed a 5 ½ year
                  degree at SDMCNYS, Ujire. She has been a registered Doctor with
                  KAUP since 2020 and is a Nutrition expert, wellness consultant
                  and focuses in reversal of lifestyle disorders.
                </p>
                <FaQuoteRight className="quote-icon right" />
              </div>
              <div className="credentials">
                <h4>Qualifications</h4>
                <ul>
                  <li>B.N.Y.S, M.D Acupuncture</li>
                  <li>P.G.D.E.M.S, P.G.D.C.T</li>
                  <li>Gold Medalist - RGUHS University</li>
                </ul>
              </div>
              <div className="expertise">
                <h4>Specializations</h4>
                <div className="expertise-tags">
                  <span>Diabetes Management</span>
                  <span>PCOD Treatment</span>
                  <span>Thyroid Care</span>
                  <span>Emergency Care</span>
                </div>
              </div>
            </div>
          </div>

          <div className="founder-card">
            <div className="founder-image-wrapper">
              <img src={images[1]} alt="Dr. Chirag M S" />
              <div className="image-overlay"></div>
            </div>
            <div className="founder-details">
              <h3>Dr. Chirag M S</h3>
              <span className="designation">Holistic Health Specialist</span>
              <div className="quote-container">
                <FaQuoteLeft className="quote-icon left" />
                <p className="founder-bio">
                  Dr. Chirag M S is a graduate of Naturopathy and Yoga from the
                  Rajiv Gandhi University of Health Sciences, Bangalore. His 
                  approach to healing is comprehensive, targeting the root cause 
                  of health issues rather than merely addressing symptoms.
                </p>
                <FaQuoteRight className="quote-icon right" />
              </div>
              <div className="credentials">
                <h4>Qualifications</h4>
                <ul>
                  <li>Naturopathy and Yoga Graduate</li>
                  <li>Registered with KAUP since 2020</li>
                  <li>Advanced Certification in Natural Therapies</li>
                </ul>
              </div>
              <div className="expertise">
                <h4>Specializations</h4>
                <div className="expertise-tags">
                  <span>Acupuncture</span>
                  <span>Herbal Medicine</span>
                  <span>Yoga Therapy</span>
                  <span>Stress Management</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
