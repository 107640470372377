import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import {
  FaUserInjured,
  FaCog,
  FaClipboard,
  FaChartLine,
  FaNotesMedical,
  FaCashRegister,
  FaUser,
  FaCalendarAlt,
  FaHospital,
  FaUserMd,
  FaFileInvoiceDollar,
  FaUserCog,
} from "react-icons/fa";
import { theme } from '../../../styles/theme';

const DashboardContainer = styled.div`
  padding: ${theme.spacing.xl};
  background-color: ${theme.colors.background};
  min-height: calc(100vh - 64px);
`;

const DashboardHeader = styled.div`
  margin-bottom: ${theme.spacing.xl};
`;

const Title = styled.h1`
  font-size: ${theme.typography.fontSizes['3xl']};
  color: ${theme.colors.dark};
  margin-bottom: ${theme.spacing.md};
`;

const Subtitle = styled.p`
  font-size: ${theme.typography.fontSizes.lg};
  color: ${theme.colors.secondary};
  margin-bottom: ${theme.spacing.xl};
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: ${theme.spacing.lg};
`;

const CardSection = styled.div`
  margin-bottom: ${theme.spacing.xl};

  h2 {
    font-size: ${theme.typography.fontSizes.xl};
    color: ${theme.colors.dark};
    margin-bottom: ${theme.spacing.lg};
    padding-bottom: ${theme.spacing.sm};
    border-bottom: 2px solid ${theme.colors.border};
  }
`;

const DashboardCard = styled.div`
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.lg};
  padding: ${theme.spacing.lg};
  box-shadow: ${theme.shadows.md};
  cursor: pointer;
  transition: all ${theme.transitions.fast};
  border: 1px solid ${theme.colors.border};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &:hover {
    transform: translateY(-4px);
    box-shadow: ${theme.shadows.lg};
    border-color: ${theme.colors.primary};
  }

  .icon-wrapper {
    width: 60px;
    height: 60px;
    border-radius: ${theme.borderRadius.full};
    background: ${props => props.color || theme.colors.primary + '10'};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${theme.spacing.md};

    svg {
      font-size: 24px;
      color: ${props => props.color || theme.colors.primary};
    }
  }

  h3 {
    font-size: ${theme.typography.fontSizes.md};
    color: ${theme.colors.dark};
    margin-bottom: ${theme.spacing.xs};
  }

  p {
    font-size: ${theme.typography.fontSizes.sm};
    color: ${theme.colors.secondary};
    margin: 0;
  }
`;

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: ${theme.spacing.md};
  margin-bottom: ${theme.spacing.xl};
`;

const StatCard = styled.div`
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.lg};
  padding: ${theme.spacing.lg};
  box-shadow: ${theme.shadows.sm};
  border: 1px solid ${theme.colors.border};

  h4 {
    font-size: ${theme.typography.fontSizes.sm};
    color: ${theme.colors.secondary};
    margin-bottom: ${theme.spacing.xs};
  }

  .value {
    font-size: ${theme.typography.fontSizes['2xl']};
    font-weight: ${theme.typography.fontWeights.bold};
    color: ${props => props.color || theme.colors.primary};
  }
`;

const Dashboard = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [stats, setStats] = useState({
    totalPatients: 0,
    totalAppointments: 0,
    totalDoctors: 0,
    totalRevenue: 0,
  });

  useEffect(() => {
    const token = sessionStorage.getItem("userRole");
    if (token) {
      setRole(token);
      // Fetch dashboard stats here
      // This is a placeholder. Replace with actual API calls
      setStats({
        totalPatients: 1250,
        totalAppointments: 45,
        totalDoctors: 28,
        totalRevenue: 125000,
      });
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const handleCardClick = (path) => {
    navigate(path);
  };

  const renderStats = () => (
    <StatsContainer>
      <StatCard>
        <h4>Total Patients</h4>
        <div className="value">{stats.totalPatients}</div>
      </StatCard>
      <StatCard color={theme.colors.success}>
        <h4>Today's Appointments</h4>
        <div className="value">{stats.totalAppointments}</div>
      </StatCard>
      <StatCard color={theme.colors.info}>
        <h4>Active Doctors</h4>
        <div className="value">{stats.totalDoctors}</div>
      </StatCard>
      <StatCard color={theme.colors.warning}>
        <h4>Revenue (₹)</h4>
        <div className="value">{stats.totalRevenue.toLocaleString()}</div>
      </StatCard>
    </StatsContainer>
  );

  const renderCard = (title, description, icon, path, color) => (
    <DashboardCard onClick={() => handleCardClick(path)} color={color}>
      <div className="icon-wrapper">
        {icon}
      </div>
      <h3>{title}</h3>
      <p>{description}</p>
    </DashboardCard>
  );

  const doctorCards = (
    <CardSection>
      <h2>Quick Actions</h2>
      <CardGrid>
        {renderCard(
          "Appointments",
          "View and manage your appointments",
          <FaCalendarAlt />,
          "/showAppointments",
          theme.colors.primary
        )}
        {renderCard(
          "Treatment Records",
          "Access and update patient treatment records",
          <FaClipboard />,
          "/AppointmentRecordManagement",
          theme.colors.success
        )}
      </CardGrid>
    </CardSection>
  );

  const receptionistCards = (
    <>
      <CardSection>
        <h2>Patient Management</h2>
        <CardGrid>
          {renderCard(
            "Patient Registration",
            "Register new patients and schedule appointments",
            <FaUserInjured />,
            "/AppointmentManagementPage",
            theme.colors.primary
          )}
          {renderCard(
            "Appointments",
            "View and manage all appointments",
            <FaCalendarAlt />,
            "/showAppointments",
            theme.colors.info
          )}
        </CardGrid>
      </CardSection>
      <CardSection>
        <h2>Records & Billing</h2>
        <CardGrid>
          {renderCard(
            "Treatment Records",
            "Access patient treatment records",
            <FaClipboard />,
            "/AppointmentRecordManagement",
            theme.colors.success
          )}
          {renderCard(
            "Billing",
            "Manage patient billing and payments",
            <FaFileInvoiceDollar />,
            "/PatientRecordsAndBilling",
            theme.colors.warning
          )}
        </CardGrid>
      </CardSection>
    </>
  );

  const adminCards = (
    <>
      <CardSection>
        <h2>Administration</h2>
        <CardGrid>
          {renderCard(
            "Department Management",
            "Manage hospital departments",
            <FaHospital />,
            "/DepartmentPage",
            theme.colors.primary
          )}
          {renderCard(
            "User Management",
            "Manage staff accounts and permissions",
            <FaUserCog />,
            "/UserManagementPage",
            theme.colors.info
          )}
        </CardGrid>
      </CardSection>
      <CardSection>
        <h2>Patient Care</h2>
        <CardGrid>
          {renderCard(
            "Patient Registration",
            "Register new patients",
            <FaUserInjured />,
            "/AppointmentManagementPage",
            theme.colors.success
          )}
          {renderCard(
            "Appointments",
            "View and manage appointments",
            <FaCalendarAlt />,
            "/showAppointments",
            theme.colors.warning
          )}
        </CardGrid>
      </CardSection>
      <CardSection>
        <h2>Records & Billing</h2>
        <CardGrid>
          {renderCard(
            "Treatment Records",
            "Access and manage treatment records",
            <FaClipboard />,
            "/AppointmentRecordManagement",
            theme.colors.info
          )}
          {renderCard(
            "Automated Billing",
            "Manage patient billing",
            <FaFileInvoiceDollar />,
            "/PatientRecordsAndBilling",
            theme.colors.primary
          )}
          {renderCard(
            "Manual Billing",
            "Generate manual bills",
            <FaCashRegister />,
            "/billing-darkMode",
            theme.colors.warning
          )}
        </CardGrid>
      </CardSection>
    </>
  );

  const welcomeMessage = {
    doctor: "Welcome back, Doctor",
    receptionist: "Reception Dashboard",
    admin: "Hospital Administration",
  };

  return (
    <DashboardContainer>
      <DashboardHeader>
        <Title>{welcomeMessage[role] || "Dashboard"}</Title>
        <Subtitle>Here's what's happening at the hospital today</Subtitle>
        {/* {role === "admin" && renderStats()} */}
      </DashboardHeader>
      
      {role === "doctor" && doctorCards}
      {role === "receptionist" && receptionistCards}
      {role === "admin" && adminCards}
      {!role && <p>Loading...</p>}
    </DashboardContainer>
  );
};

export default Dashboard;
