import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  Table,
  message,
  Pagination,
  DatePicker,
} from "antd";
import { CircularProgress } from "@mui/material";
import apiClient from "../../../common/LoginV2/axiosClient";
import moment from "moment";
import "./UserManagementPage.css";

const { Option } = Select;

const UserManagementPage = () => {
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [viewingUser, setViewingUser] = useState(null);
  const [isDoctor, setIsDoctor] = useState(false);
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [buttonLoading, setButtonLoading] = useState(false);
  const pageSize = 10;

  useEffect(() => {
    fetchUsers();
    fetchDepartments();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get("/user");
      setUsers(response.data.items);
    } catch (error) {
      message.error("Error fetching users");
    } finally {
      setLoading(false);
    }
  };
  const fetchDepartments = async () => {
    try {
      const response = await apiClient.get("/department");

      setDepartments(response.data.departments);
    } catch (error) {
      message.error("Error fetching department");
    }
  };

  const handleAddUser = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = async () => {
    setButtonLoading(true);
    try {
      const values = await form.validateFields();
      const userData = {
        ...values,
        dob: values.dob ? values.dob.format("YYYY-MM-DD") : null, // Convert moment to string
      };

      if (editingUser) {
        await apiClient.patch(`/user/${editingUser.userId}`, userData);
        setUsers(
          users.map((user) =>
            user.userId === editingUser.userId
              ? { ...user, ...userData, userId: editingUser.userId }
              : user
          )
        );
        message.success("User updated successfully");
      } else {
        await apiClient.post("/user", userData);
        setUsers([...users, userData]);
        message.success("User created successfully");
      }
      setIsModalVisible(false);
      form.resetFields();
      setEditingUser(null);
    } catch (error) {
      message.error("Failed to save user");
    } finally {
      setButtonLoading(false);
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setEditingUser(null);
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    form.setFieldsValue({
      ...user,
      dob: user.dob ? moment(user.dob, "YYYY-MM-DD") : null, // Parse `dob` to a moment object
    });
    setIsDoctor(user.role === "doctor");
    setIsModalVisible(true);
  };

  const handleDeleteUser = async () => {
    if (editingUser) {
      setButtonLoading(true);
      try {
        await apiClient.delete(`/user/${editingUser.userId}`);
        setUsers(users.filter((user) => user.userId !== editingUser.userId));
        message.success("User deleted successfully");
      } catch (error) {
        message.error("Failed to delete user");
      } finally {
        setButtonLoading(false);
      }
      setIsDeleteConfirmVisible(false);
      setEditingUser(null);
    }
  };

  const handleRoleChange = (value) => {
    setIsDoctor(value === "doctor");
    form.setFieldsValue({ department: value === "doctor" ? "" : "N/A" });
  };

  const handleViewDetails = (user) => {
    setViewingUser(user);
    setIsViewModalVisible(true);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleViewModalCancel = () => {
    setIsViewModalVisible(false);
    setViewingUser(null);
  };

  const filteredUsers = users?.filter((user) =>
    user?.userId?.toLowerCase()?.includes(searchText?.toLowerCase())
  );

  const columns = [
    { title: "User ID", dataIndex: "userId", key: "userId" },
    { title: "Name", dataIndex: "firstName", key: "firstName" }, // Adjust based on schema if necessary
    { title: "Role", dataIndex: "role", key: "role" },
    {
      title: "Action",
      key: "action",
      render: (_, user) => (
        <div className="user-management-actions">
          <Button type="link" onClick={() => handleViewDetails(user)}>
            View Details
          </Button>
          <Button type="link" onClick={() => handleEditUser(user)}>
            Edit
          </Button>
          <Button
            type="link"
            danger
            onClick={() => {
              setEditingUser(user);
              setIsDeleteConfirmVisible(true);
            }}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="user-management-container">
      <h1>User Management</h1>
      <div className="user-management-header">
        <Input.Search
          placeholder="Search user..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: "300px", marginBottom: "20px" }}
        />
        <Button type="primary" onClick={handleAddUser}>
          Create User
        </Button>
      </div>
      {
        <>
          <Table
            dataSource={filteredUsers.slice(
              (currentPage - 1) * pageSize,

              currentPage * pageSize
            )}
            columns={columns}
            rowKey="userId"
            pagination={false}
            loading={loading}
          />

          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={filteredUsers.length}
            onChange={handlePageChange}
            style={{ marginTop: "20px", textAlign: "center" }}
          />
        </>
      }

      <Modal
        title={editingUser ? "Edit User" : "Create User"}
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={[
          <Button key="cancel" onClick={handleModalCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleModalOk}
            loading={buttonLoading}
          >
            {editingUser ? "Update User" : "Create User"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: "Please input the first name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: "Please input the last name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Date of Birth"
            name="dob"
            rules={[
              { required: true, message: "Please input the date of birth!" },
            ]}
          >
            <DatePicker
              format="DD/MM/YYYY"
              placeholder="Select Date of Birth"
              style={{ width: "100%" }} // Adjust width if needed
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name="gmail"
            rules={[{ required: true, message: "Please input the email!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[
              { required: true, message: "Please input the phone number!" },

              { len: 10, message: "Phone number must be 10 digits" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Address"
            name="address"
            rules={[{ required: true, message: "Please input the address!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Gender"
            name="gender"
            rules={[{ required: true, message: "Please select the gender!" }]}
          >
            <Select>
              <Option value="Male">Male</Option>
              <Option value="Female">Female</Option>
              <Option value="Other">Other</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Aadhaar Number"
            name="aadharNumber"
            rules={[
              { required: true, message: "Please input the Aadhaar number!" },

              { len: 12, message: "Aadhaar number must be 12 digits" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="PAN Number"
            name="PAN"
            rules={[
              { required: true, message: "Please input the PAN number!" },

              { len: 10, message: "PAN number must be 10 characters" },
            ]}
          >
            <Input />
          </Form.Item>
          {!editingUser && (
            <>
              <Form.Item
                label="userId"
                name="userId"
                rules={[
                  { required: true, message: "Please input the userId!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input the password!" },

                  { min: 8, message: "Password must be at least 8 characters" },

                  {
                    pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,

                    message:
                      "Password must contain uppercase, lowercase, and a number",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </>
          )}
          <Form.Item
            label="Role"
            name="role"
            rules={[{ required: true, message: "Please select the role!" }]}
          >
            <Select onChange={handleRoleChange}>
              <Option value="doctor">Doctor</Option>
              <Option value="receptionist">Receptionist</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Department"
            name="department"
            rules={[
              { required: isDoctor, message: "Please select a department!" },
            ]}
          >
            <Select disabled={!isDoctor}>
              {departments.map((department) => (
                <Option key={department} value={department}>
                  {department}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Confirm Delete"
        visible={isDeleteConfirmVisible}
        onOk={handleDeleteUser}
        onCancel={() => setIsDeleteConfirmVisible(false)}
        okText="Delete"
        okButtonProps={{ danger: true }}
        cancelText="Cancel"
        disabled={buttonLoading}
      >
        <p>Are you sure you want to delete this user?</p>
      </Modal>

      <Modal
        title="User Details"
        visible={isViewModalVisible}
        onCancel={handleViewModalCancel}
        footer={<Button onClick={handleViewModalCancel}>Close</Button>}
      >
        {viewingUser && (
          <div>
            <p>
              <strong>First Name:</strong> {viewingUser.firstName}
            </p>
            <p>
              <strong>Last Name:</strong> {viewingUser.lastName}
            </p>
            <p>
              <strong>Date of Birth:</strong>{" "}
              {viewingUser.dob
                ? moment(viewingUser.dob).format("DD/MM/YYYY")
                : "N/A"}
            </p>

            <p>
              <strong>Email:</strong> {viewingUser.gmail}
            </p>
            <p>
              <strong>Gender:</strong> {viewingUser.gender}
            </p>
            <p>
              <strong>Address:</strong> {viewingUser.address}
            </p>
            <p>
              <strong>Phone Number:</strong> {viewingUser.phoneNumber}
            </p>
            <p>
              <strong>Aadhaar Number:</strong> {viewingUser.aadharNumber}
            </p>
            <p>
              <strong>pan Number:</strong> {viewingUser.PAN}
            </p>
            <p>
              <strong>Role:</strong> {viewingUser.role}
            </p>
            <p>
              <strong>Department:</strong> {viewingUser.department}
            </p>
            <p>
              <strong>UserId:</strong> {viewingUser.userId}
            </p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default UserManagementPage;
