import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: ${props => props.theme.typography.fontFamily};
    background-color: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.dark};
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: ${props => props.theme.typography.fontWeights.bold};
    margin-bottom: ${props => props.theme.spacing.md};
  }

  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
    transition: color ${props => props.theme.transitions.fast};

    &:hover {
      color: ${props => props.theme.colors.info};
    }
  }

  button {
    cursor: pointer;
    font-family: inherit;
    border: none;
    background: none;
    
    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  input, textarea, select {
    font-family: inherit;
    font-size: ${props => props.theme.typography.fontSizes.md};
  }

  // Improved table styles
  table {
    width: 100%;
    border-collapse: collapse;
    margin: ${props => props.theme.spacing.md} 0;
    
    th, td {
      padding: ${props => props.theme.spacing.sm} ${props => props.theme.spacing.md};
      border-bottom: 1px solid ${props => props.theme.colors.border};
      text-align: left;
    }

    th {
      background-color: ${props => props.theme.colors.light};
      font-weight: ${props => props.theme.typography.fontWeights.semibold};
    }

    tr:hover {
      background-color: ${props => props.theme.colors.light};
    }
  }

  // Improved form styles
  .form-group {
    margin-bottom: ${props => props.theme.spacing.md};

    label {
      display: block;
      margin-bottom: ${props => props.theme.spacing.xs};
      font-weight: ${props => props.theme.typography.fontWeights.medium};
    }

    input, textarea, select {
      width: 100%;
      padding: ${props => props.theme.spacing.sm};
      border: 1px solid ${props => props.theme.colors.border};
      border-radius: ${props => props.theme.borderRadius.md};
      transition: border-color ${props => props.theme.transitions.fast};

      &:focus {
        outline: none;
        border-color: ${props => props.theme.colors.primary};
      }
    }
  }

  // Improved card styles
  .card {
    background: ${props => props.theme.colors.white};
    border-radius: ${props => props.theme.borderRadius.lg};
    box-shadow: ${props => props.theme.shadows.md};
    padding: ${props => props.theme.spacing.lg};
    margin-bottom: ${props => props.theme.spacing.lg};
  }

  // Responsive grid system
  .grid {
    display: grid;
    gap: ${props => props.theme.spacing.md};
    
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      grid-template-columns: repeat(2, 1fr);
    }
    
    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  // Improved pagination styles
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${props => props.theme.spacing.sm};
    margin: ${props => props.theme.spacing.lg} 0;

    button {
      padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.sm};
      border: 1px solid ${props => props.theme.colors.border};
      border-radius: ${props => props.theme.borderRadius.md};
      background: ${props => props.theme.colors.white};
      transition: all ${props => props.theme.transitions.fast};

      &:hover:not(:disabled) {
        background: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.white};
        border-color: ${props => props.theme.colors.primary};
      }

      &.active {
        background: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.white};
        border-color: ${props => props.theme.colors.primary};
      }
    }
  }
`;
