import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import styled from 'styled-components';
import { Button, Tooltip } from "antd";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import apiClient from "../../../common/LoginV2/axiosClient";
import { theme } from '../../../../styles/theme';

const PageContainer = styled.div`
  padding: ${theme.spacing.xl};
  background-color: ${theme.colors.background};
  min-height: calc(100vh - 64px);
`;

const PageHeader = styled.div`
  margin-bottom: ${theme.spacing.xl};
`;

const Title = styled.h1`
  font-size: ${theme.typography.fontSizes['2xl']};
  color: ${theme.colors.dark};
  margin-bottom: ${theme.spacing.sm};
`;

const Subtitle = styled.p`
  font-size: ${theme.typography.fontSizes.md};
  color: ${theme.colors.secondary};
  margin-bottom: ${theme.spacing.xl};
`;

const AddDepartmentSection = styled.div`
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.lg};
  padding: ${theme.spacing.lg};
  box-shadow: ${theme.shadows.sm};
  margin-bottom: ${theme.spacing.xl};
  display: flex;
  gap: ${theme.spacing.md};
  align-items: center;
`;

const TableContainer = styled.div`
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.lg};
  padding: ${theme.spacing.lg};
  box-shadow: ${theme.shadows.sm};
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  
  th, td {
    padding: ${theme.spacing.md};
    text-align: left;
    border-bottom: 1px solid ${theme.colors.border};
  }

  th {
    background: ${theme.colors.background};
    color: ${theme.colors.dark};
    font-weight: ${theme.typography.fontWeights.semibold};
    font-size: ${theme.typography.fontSizes.sm};
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  tr:hover td {
    background-color: ${theme.colors.background};
  }

  td {
    font-size: ${theme.typography.fontSizes.sm};
    color: ${theme.colors.secondary};
  }
`;

const ActionButton = styled(IconButton)`
  &.MuiIconButton-root {
    color: ${props => props.color || theme.colors.primary};
    margin: 0 ${theme.spacing.xs};
    
    &:hover {
      background-color: ${props => props.color ? `${props.color}15` : `${theme.colors.primary}15`};
    }
  }
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: ${theme.borderRadius.lg};
    padding: ${theme.spacing.md};
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    padding: ${theme.spacing.lg};
    background: ${theme.colors.background};
    border-radius: ${theme.borderRadius.lg} ${theme.borderRadius.lg} 0 0;
    margin: -${theme.spacing.md} -${theme.spacing.md} ${theme.spacing.md} -${theme.spacing.md};
  }
`;

const ErrorMessage = styled.div`
  color: ${theme.colors.danger};
  font-size: ${theme.typography.fontSizes.sm};
  margin-top: ${theme.spacing.sm};
`;

const DoctorList = styled(List)`
  &.MuiList-root {
    padding: 0;
  }
`;

const DoctorListItem = styled(ListItem)`
  &.MuiListItem-root {
    border-bottom: 1px solid ${theme.colors.border};
    padding: ${theme.spacing.md};
    
    &:last-child {
      border-bottom: none;
    }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
`;

const DepartmentPage = () => {
  const [departments, setDepartments] = useState([]);
  const [newDepartment, setNewDepartment] = useState("");
  const [doctorDialogOpen, setDoctorDialogOpen] = useState(false);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [departmentToDelete, setDepartmentToDelete] = useState(null);
  const [editDepartment, setEditDepartment] = useState(null);
  const [editDepartmentName, setEditDepartmentName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDepartments = async () => {
      setLoading(true);
      try {
        const response = await apiClient.get("/department/users/list");
        setDepartments(response.data.departments);
      } catch (error) {
        console.error("Error fetching departments:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchDepartments();
  }, []);

  const handleAddDepartment = async () => {
    if (newDepartment && !departments.some((dept) => dept.department === newDepartment)) {
      setLoading(true);
      try {
        const response = await apiClient.post("/department", {
          department: newDepartment,
        });
        setDepartments([...departments, response.data]);
        setNewDepartment("");
      } catch (error) {
        console.error("Error adding department:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEditDepartment = (department) => {
    setEditDepartment(department);
    setEditDepartmentName(department.department);
  };

  const handleSaveEdit = async () => {
    setButtonLoading(true);
    try {
      await apiClient.patch(`/department/${editDepartment.department}`, {
        department: editDepartmentName,
      });
      setDepartments(
        departments.map((dept) =>
          dept.department === editDepartment.department
            ? { ...dept, department: editDepartmentName }
            : dept
        )
      );
      setEditDepartment(null);
      setEditDepartmentName("");
    } catch (error) {
      console.error("Error updating department:", error);
    } finally {
      setButtonLoading(false);
    }
  };

  const handleDeleteDepartment = async () => {
    setDeleteButtonLoading(true);
    setErrorMessage("");
    try {
      await apiClient.delete(`/department/${departmentToDelete.department}`);
      setDepartments(
        departments.filter(
          (dept) => dept.department !== departmentToDelete.department
        )
      );
      setDeleteDialogOpen(false);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setErrorMessage(error.response.data.detail);
      } else {
        console.error("Error deleting department:", error);
      }
    } finally {
      setDeleteButtonLoading(false);
    }
  };

  const handleViewDoctors = (department) => {
    setSelectedDoctors(department.doctors.map((doctor) => doctor.name));
    setDoctorDialogOpen(true);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Sl. No",
        accessor: (_, rowIndex) => rowIndex + 1,
        id: "index",
        Cell: ({ value }) => (
          <Typography variant="body2" color="textSecondary">
            {value}
          </Typography>
        ),
      },
      {
        Header: "Department Name",
        accessor: "department",
        Cell: ({ value }) => (
          <Typography variant="body2" style={{ fontWeight: 500, color: theme.colors.dark }}>
            {value}
          </Typography>
        ),
      },
      {
        Header: "Doctors",
        accessor: "doctors",
        Cell: ({ row }) => (
          row.original.doctors && row.original.doctors.length > 0 ? (
            <Tooltip title="View Doctors">
              <ActionButton
                size="small"
                onClick={() => handleViewDoctors(row.original)}
              >
                <PersonIcon />
              </ActionButton>
            </Tooltip>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No doctors assigned
            </Typography>
          )
        ),
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Tooltip title="Edit Department">
              <ActionButton
                size="small"
                onClick={() => handleEditDepartment(row.original)}
                color={theme.colors.info}
              >
                <EditIcon />
              </ActionButton>
            </Tooltip>
            <Tooltip title="Delete Department">
              <ActionButton
                size="small"
                onClick={() => {
                  setDeleteDialogOpen(true);
                  setDepartmentToDelete(row.original);
                }}
                color={theme.colors.danger}
              >
                <DeleteIcon />
              </ActionButton>
            </Tooltip>
          </div>
        ),
      },
    ],
    [departments]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: departments });

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );
  }

  return (
    <PageContainer>
      <PageHeader>
        <Title>Department Management</Title>
        <Subtitle>Manage hospital departments and their assigned doctors</Subtitle>
      </PageHeader>

      <AddDepartmentSection>
        <TextField
          label="Department Name"
          variant="outlined"
          value={newDepartment}
          onChange={(e) => setNewDepartment(e.target.value)}
          fullWidth
          size="small"
        />
        <Button
          type="primary"
          icon={<AddIcon />}
          onClick={handleAddDepartment}
          loading={loading}
        >
          Add Department
        </Button>
      </AddDepartmentSection>

      <TableContainer>
        <StyledTable {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </TableContainer>

      {/* Edit Department Dialog */}
      <StyledDialog
        open={editDepartment !== null}
        onClose={() => setEditDepartment(null)}
        maxWidth="sm"
        fullWidth
      >
        <StyledDialogTitle>
          Edit Department
          <IconButton
            aria-label="close"
            onClick={() => setEditDepartment(null)}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Department Name"
            fullWidth
            variant="outlined"
            value={editDepartmentName}
            onChange={(e) => setEditDepartmentName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDepartment(null)}>Cancel</Button>
          <Button
            type="primary"
            onClick={handleSaveEdit}
            loading={buttonLoading}
          >
            Save Changes
          </Button>
        </DialogActions>
      </StyledDialog>

      {/* Delete Confirmation Dialog */}
      <StyledDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <StyledDialogTitle>
          Delete Department
          <IconButton
            aria-label="close"
            onClick={() => setDeleteDialogOpen(false)}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this department?
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button
            type="primary"
            danger
            onClick={handleDeleteDepartment}
            loading={deleteButtonLoading}
          >
            Delete
          </Button>
        </DialogActions>
      </StyledDialog>

      {/* View Doctors Dialog */}
      <StyledDialog
        open={doctorDialogOpen}
        onClose={() => setDoctorDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <StyledDialogTitle>
          Department Doctors
          <IconButton
            aria-label="close"
            onClick={() => setDoctorDialogOpen(false)}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent>
          <DoctorList>
            {selectedDoctors.map((doctor, index) => (
              <DoctorListItem key={index}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={doctor} />
              </DoctorListItem>
            ))}
          </DoctorList>
        </DialogContent>
      </StyledDialog>
    </PageContainer>
  );
};

export default DepartmentPage;
