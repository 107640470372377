import React, { useEffect, useState } from "react";
import apiClient from "../../../common/LoginV2/axiosClient";
import {
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  Select,
  Table,
  notification,
} from "antd";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import "./AppointmentManagementPage.css";

const { Option } = Select;

const AppointmentManagementPage = () => {
  const [patients, setPatients] = useState([]); // Holds the list of patients
  const [filteredPatients, setFilteredPatients] = useState([]); // Filtered patient list
  const [isModalVisible, setIsModalVisible] = useState(false); // Controls patient modal visibility
  const [isEditing, setIsEditing] = useState(false); // Tracks editing state
  const [editingPatient, setEditingPatient] = useState(null); // Tracks the patient being edited
  const [form] = Form.useForm(); // Form instance for patient registration
  const [searchTerm, setSearchTerm] = useState(""); // Tracks search input
  const [doctors, setDoctors] = useState([]); // Holds the list of doctors
  const [departments, setDepartments] = useState([]); // Holds the list of departments
  const [isAppointmentModalVisible, setIsAppointmentModalVisible] =
    useState(false); // Controls appointment modal visibility
  const [isShowAppointmentsModalVisible, setIsShowAppointmentsModalVisible] =
    useState(false); // Controls show appointments modal visibility
  const [appointmentForm] = Form.useForm(); // Form instance for appointment creation
  const [currentPatientId, setCurrentPatientId] = useState(null); // Tracks selected patient ID for appointments
  const [appointments, setAppointments] = useState([]); // Holds list of appointments for a patient
  const [loading, setLoading] = useState(false); // Tracks overall loading state
  const [updateButtonLoading, setUpdateButtonLoading] = useState(false); // Tracks patient update button loading
  const [showButtonLoading, setShowButtonLoading] = useState(false); // Tracks show appointments button loading
  const [createAppointmentButtonLoading, setCreateAppointmentButtonLoading] =
    useState(false); // Tracks create appointment button loading

  // Fetch patients from API on component mount
  const fetchPatients = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get("/patientRegistration");
      const { items } = response.data;
      const formattedItems = items.map((item) => ({
        id: item.id,
        firstName: item.firstName,
        lastName: item.lastName,
        dob: item.dob,
        gmail: item.gmail,
        phoneNumber: item.phoneNumber,
        gender: item.gender,
        address: item.address,
      }));
      setPatients(formattedItems);
      setFilteredPatients(formattedItems);
    } catch (error) {
      console.error("Error fetching patients:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch doctors and departments for appointment creation
  const fetchDoctorsAndDepartments = async () => {
    try {
      const doctorResponse = await apiClient.get("/user");
      const departmentResponse = await apiClient.get("/department");
      setDoctors(doctorResponse.data.items.filter((item)=>item.role==="doctor"));
      setDepartments(departmentResponse.data.departments);
    } catch (error) {
      console.error("Error fetching doctors or departments:", error);
    }
  };

  // Run fetch operations on component mount
  useEffect(() => {
    fetchPatients();
    fetchDoctorsAndDepartments();
  }, []);

  // Filter patients by name or phone number
  useEffect(() => {
    const filtered = patients.filter(
      (patient) =>
        patient.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patient.phoneNumber.includes(searchTerm)
    );
    setFilteredPatients(filtered);
  }, [searchTerm, patients]);

  // Opens patient registration modal
  const handleAddPatient = () => {
    form.resetFields();
    setIsModalVisible(true);
    setIsEditing(false);
  };

  // Opens patient editing modal with pre-filled data
  const handleEditPatient = (record) => {
    form.setFieldsValue({
      ...record,
      dob: moment(record.dob),
    });
    setIsModalVisible(true);
    setIsEditing(true);
    setEditingPatient(record);
  };

  // Handles patient registration or update
  const handleFormSubmit = async (values) => {
    setUpdateButtonLoading(true);
    const patientData = {
      firstName: String(values.firstName),
      lastName: String(values.lastName),
      dob: values.dob ? values.dob.format("YYYY-MM-DD") : "",
      gmail: String(values.gmail || ""),
      phoneNumber: String(values.phoneNumber),
      gender: String(values.gender),
      address: String(values.address || ""),
    };
    try {
      if (isEditing) {
        await apiClient.patch(
          `/patientRegistration/${editingPatient.id}`,
          patientData
        );
        setPatients(
          patients.map((item) =>
            item.id === editingPatient.id ? { ...item, ...patientData } : item
          )
        );
      } else {
        const response = await apiClient.post(
          "/patientRegistration",
          patientData
        );
        setPatients([...patients, { ...patientData, id: response.data._id }]);
      }
      setIsModalVisible(false);
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving patient:", error);
    } finally {
      setUpdateButtonLoading(false);
    }
  };

  // Opens appointment creation modal
  const handleCreateAppointment = (patientId) => {
    setCurrentPatientId(patientId);
    appointmentForm.resetFields();
    setIsAppointmentModalVisible(true);
  };

  // Submits appointment creation form
  const handleAppointmentSubmit = async (values) => {
    setCreateAppointmentButtonLoading(true);
    const appointmentData = {
      patientId: currentPatientId,
      departmentId: values.departmentId,
      doctorId: values.doctorId,
      appointmentDate: values.appointmentDate.format("YYYY-MM-DD"),
      appointmentTime: values.appointmentTime.format("HH:mm"),
    };
    try {
      await apiClient.post("/appointment", appointmentData);
      notification.success({ message: "Appointment created successfully!" });
      setIsAppointmentModalVisible(false);
    } catch (error) {
      console.error("Error creating appointment:", error);
      notification.error({ message: "Failed to create appointment!" });
    } finally {
      setCreateAppointmentButtonLoading(false);
    }
  };

  // Fetch and show patient appointments
  const handleShowAppointments = async (patientId) => {
    setCurrentPatientId(patientId);
    setShowButtonLoading(true);
    try {
      const response = await apiClient.get(
        `/appointment?filters=patientId:eq:${patientId}`
      );
      setAppointments(response.data.items);
      setIsShowAppointmentsModalVisible(true);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    } finally {
      setShowButtonLoading(false);
    }
  };

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Actions",
      key: "action",
      render: (text, record) => (
        <div className="appointment-management-actions">
          <Button type="link" onClick={() => handleEditPatient(record)}>
            Edit
          </Button>
          <Button
            type="link"
            onClick={() => handleCreateAppointment(record.id)}
          >
            Create Appointment
          </Button>
          <Button
            type="link"
            onClick={() => handleShowAppointments(record.id)}
            loading={showButtonLoading}
          >
            Show Appointments
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="appointment-management-container">
      <div className="appointment-management-header">
        <h1>Patient Management</h1>
        <Input
          placeholder="Search by Name or Phone Number"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "300px", marginRight: "16px" }}
        />
        <Button type="primary" onClick={handleAddPatient}>
          Register Patient
        </Button>
      </div>
      {
        <Table columns={columns} loading={loading} dataSource={filteredPatients} rowKey="id" />
      }

      {/* Modal for patient registration or editing */}
      <Modal
        title={isEditing ? "Edit Patient" : "Register Patient"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => setIsModalVisible(false)}
            disabled={updateButtonLoading}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            loading={updateButtonLoading}
          >
            {isEditing ? "Update" : "Register"}
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFormSubmit}
          initialValues={{
            gender: "Male",
          }}
        >
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: "First name is required." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: "Last name is required." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Date of Birth"
            name="dob"
            rules={[{ required: false, message: "Date of birth is required." }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[
              { required: true, message: "Phone number is required." },
              { len: 10, message: "Phone number must be 10 digits." },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Gender"
            name="gender"
            rules={[{ required: true, message: "Gender is required." }]}
          >
            <Select>
              <Option value="Male">Male</Option>
              <Option value="Female">Female</Option>
              <Option value="Other">Other</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Email"
            name="gmail"
            rules={[{ type: "email", message: "Invalid email address." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Address" name="address">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>

      {/* Modal for appointment creation */}
      <Modal
        title="Create Appointment"
        visible={isAppointmentModalVisible}
        onCancel={() => setIsAppointmentModalVisible(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => setIsAppointmentModalVisible(false)}
            disabled={createAppointmentButtonLoading}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => appointmentForm.submit()}
            loading={createAppointmentButtonLoading}
          >
            Create Appointment
          </Button>,
        ]}
      >
        <Form
          form={appointmentForm}
          layout="vertical"
          onFinish={handleAppointmentSubmit}
        >
          <Form.Item
            label="Department"
            name="departmentId"
            rules={[{ required: true, message: "Department is required." }]}
          >
            <Select>
              {departments.map((department) => (
                <Option key={department} value={department}>
                  {department}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Doctor"
            name="doctorId"
            rules={[{ required: true, message: "Doctor is required." }]}
          >
            <Select>
              {doctors.map((doctor) => (
                <Option key={doctor.id} value={doctor.id}>
                  {doctor.firstName} {doctor.lastName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Appointment Date"
            name="appointmentDate"
            rules={[
              { required: true, message: "Appointment date is required." },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Appointment Time"
            name="appointmentTime"
            rules={[
              { required: true, message: "Appointment time is required." },
            ]}
          >
            <DatePicker picker="time" format="HH:mm"/>
          </Form.Item>
        </Form>
      </Modal>

      {/* Modal to display patient appointments */}
      <Modal
        title="Appointments"
        visible={isShowAppointmentsModalVisible}
        onCancel={() => setIsShowAppointmentsModalVisible(false)}
        footer={[
          <Button
            key="close"
            onClick={() => setIsShowAppointmentsModalVisible(false)}
          >
            Close
          </Button>,
        ]}
      >
        {appointments.length === 0 ? (
          <p>No appointments found.</p>
        ) : (
          <Table
            dataSource={appointments}
            rowKey="id"
            columns={[
              {
                title: "Date",
                dataIndex: "appointmentDate",
                key: "appointmentDate",
              },
              {
                title: "Time",
                dataIndex: "appointmentTime",
                key: "appointmentTime",
              },
              {
                title: "Doctor",
                dataIndex: "doctorName",
                key: "doctorName",
              },
              {
                title: "Department",
                dataIndex: "departmentId",
                key: "departmentId",
              },
            ]}
          />
        )}
      </Modal>
    </div>
  );
};

export default AppointmentManagementPage;
