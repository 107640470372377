import React, { useState } from 'react';
import './Services.css';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTimes, FaHeart, FaLeaf, FaYinYang, FaSpa, FaClinicMedical, FaBandAid } from 'react-icons/fa';
import { scrollToSection } from '../../../utils/scrollUtils';

const services = [
    {
        title: "Trichology",
        icon: <FaSpa />,
        description: "Expert hair care and scalp treatments",
        color: "#9C27B0",
        details: {
            description: "Our trichology services focus on the health of your hair and scalp using advanced diagnostic techniques and treatments.",
            treatments: [
                "Hair Loss Treatment",
                "Scalp Analysis",
                "PRP Therapy",
                "Hair Growth Stimulation",
                "Customized Hair Care Plans"
            ],
            benefits: [
                "Improved hair growth",
                "Healthier scalp condition",
                "Reduced hair loss",
                "Enhanced hair texture",
                "Long-lasting results"
            ],
            duration: "45-60 minutes per session",
            price: "Starting from ₹2,500"
        }
    },
    {
        title: "Yoga",
        icon: <FaYinYang />,
        description: "Holistic wellness through yoga",
        color: "#4CAF50",
        details: {
            description: "Experience transformative yoga sessions tailored to your needs and goals.",
            treatments: [
                "Hatha Yoga",
                "Vinyasa Flow",
                "Meditation Sessions",
                "Breathing Exercises",
                "Stress Relief Techniques"
            ],
            benefits: [
                "Improved flexibility",
                "Stress reduction",
                "Better posture",
                "Enhanced mental clarity",
                "Increased strength"
            ],
            duration: "60-90 minutes per session",
            price: "Starting from ₹1,299"
        }
    },
    {
        title: "Diet Plans",
        icon: <FaLeaf />,
        description: "Personalized nutrition guidance",
        color: "#FF9800",
        details: {
            description: "Custom nutrition plans designed to meet your health goals and dietary preferences.",
            treatments: [
                "Personalized Meal Plans",
                "Nutritional Assessment",
                "Diet Consultation",
                "Weight Management",
                "Supplement Guidance"
            ],
            benefits: [
                "Healthy weight management",
                "Increased energy levels",
                "Better digestion",
                "Improved immunity",
                "Sustainable lifestyle changes"
            ],
            duration: "Initial consultation: 60 minutes",
            price: "Starting from ₹1,499"
        }
    },
    {
        title: "Acupuncture",
        icon: <FaBandAid />,
        description: "Traditional healing techniques",
        color: "#E91E63",
        details: {
            description: "Ancient healing practice using precise needle placement to balance energy flow.",
            treatments: [
                "Pain Management",
                "Stress Relief",
                "Fertility Treatment",
                "Digestive Health",
                "Immune System Boost"
            ],
            benefits: [
                "Natural pain relief",
                "Improved circulation",
                "Better sleep quality",
                "Reduced anxiety",
                "Enhanced wellbeing"
            ],
            duration: "30-45 minutes per session",
            price: "Starting from ₹650"
        }
    },
    {
        title: "Homeopathy",
        icon: <FaClinicMedical />,
        description: "Natural healing solutions",
        color: "#2196F3",
        details: {
            description: "Gentle, natural treatments that work with your body's healing processes.",
            treatments: [
                "Constitutional Treatment",
                "Acute Care",
                "Chronic Disease Management",
                "Pediatric Care",
                "Women's Health"
            ],
            benefits: [
                "No side effects",
                "Long-term healing",
                "Improved immunity",
                "Better overall health",
                "Natural recovery"
            ],
            duration: "Consultation: 45-60 minutes",
            price: "Starting from ₹1,199"
        }
    },
    {
        title: "Wellness",
        icon: <FaHeart />,
        description: "Complete health programs",
        color: "#673AB7",
        details: {
            description: "Comprehensive wellness programs combining various therapeutic approaches.",
            treatments: [
                "Health Assessment",
                "Lifestyle Coaching",
                "Stress Management",
                "Detox Programs",
                "Anti-aging Treatments"
            ],
            benefits: [
                "Holistic healing",
                "Improved vitality",
                "Better life quality",
                "Sustained wellness",
                "Preventive care"
            ],
            duration: "Varies by program",
            price: "Starting from ₹4,999"
        }
    }
];

const Services = () => {
    const [selectedService, setSelectedService] = useState(null);

    const openModal = (service) => {
        setSelectedService(service);
    };

    const closeModal = () => {
        setSelectedService(null);
    };

    const handleBookNow = () => {
        scrollToSection('.appointment');
        if (selectedService) {
            setSelectedService(null);
        }
    };

    return (
        <section className="services-section" id="services">
            <div className="services-container">
                <motion.div 
                    className="section-header"
                    initial={{ opacity: 0, y: -50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                >
                    <h2>Our Services</h2>
                    <p>Discover our range of holistic wellness services</p>
                </motion.div>

                <div className="services-grid">
                    {services.map((service, index) => (
                        <motion.div
                            key={index}
                            className="service-card"
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            onClick={() => openModal(service)}
                            style={{ '--service-color': service.color }}
                        >
                            <div className="service-icon">
                                {service.icon}
                            </div>
                            <h3>{service.title}</h3>
                            <p>{service.description}</p>
                            <button className="view-details-btn">View Details</button>
                        </motion.div>
                    ))}
                </div>
            </div>

            <AnimatePresence>
                {selectedService && (
                    <>
                        <motion.div 
                            className="modal-overlay"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            onClick={closeModal}
                        />
                        <motion.div 
                            className="service-modal"
                            initial={{ opacity: 0, scale: 0.8, y: 50 }}
                            animate={{ opacity: 1, scale: 1, y: 0 }}
                            exit={{ opacity: 0, scale: 0.8, y: 50 }}
                        >
                            <button className="close-modal" onClick={closeModal}>
                                <FaTimes />
                            </button>
                            
                            <div className="modal-icon" style={{ backgroundColor: selectedService.color }}>
                                {selectedService.icon}
                            </div>
                            
                            <h3>{selectedService.title}</h3>
                            <p className="modal-description">{selectedService.details.description}</p>
                            
                            <div className="modal-details">
                                <div className="detail-section">
                                    <h4>Treatments Offered</h4>
                                    <ul>
                                        {selectedService.details.treatments.map((treatment, index) => (
                                            <li key={index}>{treatment}</li>
                                        ))}
                                    </ul>
                                </div>
                                
                                <div className="detail-section">
                                    <h4>Benefits</h4>
                                    <ul>
                                        {selectedService.details.benefits.map((benefit, index) => (
                                            <li key={index}>{benefit}</li>
                                        ))}
                                    </ul>
                                </div>
                                
                                <div className="session-info">
                                    <div className="info-item">
                                        <span className="label">Duration:</span>
                                        <span className="value">{selectedService.details.duration}</span>
                                    </div>
                                    <div className="info-item">
                                        <span className="label">Price:</span>
                                        <span className="value">{selectedService.details.price}</span>
                                    </div>
                                </div>
                            </div>
                            <motion.button
                                className="book-now-btn"
                                onClick={handleBookNow}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                Book Now
                            </motion.button>
                        </motion.div>
                    </>
                )}
            </AnimatePresence>
        </section>
    );
};

export default Services;
