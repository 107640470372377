import React, { useState } from 'react';
import './AppointmentForm.css';
import { FaTimes, FaCalendar, FaClock, FaUser, FaPhone, FaEnvelope, FaComments } from 'react-icons/fa';

const AppointmentForm = ({ onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        date: '',
        time: '',
        service: 'Trichology',
        message: ''
    });

    const services = [
        'Trichology',
        'Yoga',
        'Diet Plans',
        'Acupuncture',
        'Homeopathy',
        'Wellness'
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here
        console.log('Form submitted:', formData);
        onClose();
    };

    return (
        <div className="appointment-form-container">
            <button className="close-form" onClick={onClose}>
                <FaTimes />
            </button>
            
            <div className="form-header">
                <h2>Book an Appointment</h2>
                <p>Fill out the form below and we'll get back to you soon.</p>
            </div>

            <form onSubmit={handleSubmit} className="appointment-form">
                <div className="form-group">
                    <div className="input-icon">
                        <FaUser />
                    </div>
                    <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <div className="input-icon">
                        <FaEnvelope />
                    </div>
                    <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <div className="input-icon">
                        <FaPhone />
                    </div>
                    <input
                        type="tel"
                        name="phone"
                        placeholder="Phone Number"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <div className="input-icon">
                            <FaCalendar />
                        </div>
                        <input
                            type="date"
                            name="date"
                            value={formData.date}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <div className="input-icon">
                            <FaClock />
                        </div>
                        <input
                            type="time"
                            name="time"
                            value={formData.time}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>

                <div className="form-group">
                    <select
                        name="service"
                        value={formData.service}
                        onChange={handleChange}
                        required
                    >
                        {services.map((service, index) => (
                            <option key={index} value={service}>
                                {service}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <div className="input-icon">
                        <FaComments />
                    </div>
                    <textarea
                        name="message"
                        placeholder="Additional Notes (Optional)"
                        value={formData.message}
                        onChange={handleChange}
                        rows="4"
                    ></textarea>
                </div>

                <button type="submit" className="submit-btn">
                    Book Appointment
                </button>
            </form>
        </div>
    );
};

export default AppointmentForm;
