import React, { useState } from 'react';
import './HeroSection.css';
import { motion } from 'framer-motion';
import AppointmentModal from '../AppointmentModal/AppointmentModal';

const HeroSection = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const scrollToAppointment = () => {
        const appointmentSection = document.querySelector('.appointment');
        if (appointmentSection) {
            appointmentSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const openWhatsApp = () => {
        // Replace with your actual WhatsApp number
        const phoneNumber = '7204461364';
        const whatsappUrl = `https://wa.me/${phoneNumber}`;
        window.open(whatsappUrl, '_blank');
    };

    return (
        <section className="hero-section">
            <div className="hero-overlay"></div>
            <div className="hero-content">
                <motion.div 
                    className="hero-text"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                >
                    <h1>Your Journey to Natural Wellness</h1>
                    <p>Experience holistic healing through our integrated approach to health and wellness</p>
                    <div className="hero-buttons">
                        <motion.button 
                            className="book-button"
                            onClick={scrollToAppointment}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            Book Appointment
                        </motion.button>
                        <motion.button 
                            className="whatsapp-button"
                            onClick={openWhatsApp}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            WhatsApp Us
                        </motion.button>
                    </div>
                </motion.div>

                <motion.div 
                    className="floating-card"
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, delay: 0.3, ease: "easeOut" }}
                >
                    <h3>Natural Healing</h3>
                    <p>Discover the power of holistic treatments and natural remedies for lasting wellness.</p>
                    <ul>
                        <li>Personalized Care</li>
                        <li>Expert Practitioners</li>
                        <li>Natural Solutions</li>
                    </ul>
                </motion.div>
            </div>

            <AppointmentModal 
                isOpen={isModalOpen} 
                onClose={closeModal}
            />
        </section>
    );
};

export default HeroSection;
